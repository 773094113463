import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const Formdata = () => {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    userName: Yup.string().required("User Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    country: Yup.string().required("Country is required"),
    gender: Yup.string()
      .oneOf(["male", "female", "other"], "Invalid gender")
      .required("Gender is required"),
    preference: Yup.string()
      .oneOf(
        ["option1", "option2", "option3", "option4"],
        "Please select a preference"
      )
      .required("Preference is required"),
  });

  // onSubmit handler to trigger the alert
  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    // Check if the form has errors or is invalid
    if (
      !values.firstName ||
      !values.userName ||
      !values.email ||
      !values.country ||
      !values.gender
    ) {
      console.log("Please fill out the form correctly before submitting.");
      alert("Please fill out the form correctly before submitting.");
    } else {
      console.log("values>>>", values);
      alert("Form submitted successfully!");
    }
    setSubmitting(false); // Stop the submitting state
  };

  return (
    <>
      <div>this is</div>
      <Formik
        initialValues={{
          firstName: "",
          userName: "",
          email: "",
          country: "",
          gender: "",
          preference: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <div className="row">
              <div className="col-lg-6">
                {/* First Name */}
                <div className="">
                  <label htmlFor="firstName">First Name</label>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter First Name"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                {/* User Name */}
                <div>
                  <label htmlFor="userName">User Name</label>
                  <Field
                    type="text"
                    id="userName"
                    name="userName"
                    placeholder="Enter User Name"
                  />
                  <ErrorMessage
                    name="userName"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            </div>

            {/* Email */}
            <div>
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email"
              />
              <ErrorMessage
                name="email"
                component="div"
                style={{ color: "red" }}
              />
            </div>

            {/* Country */}
            <div>
              <label htmlFor="country">Country</label>
              <Field as="select" id="country" name="country">
                <option value="">Select Country</option>
                <option value="USA">USA</option>
                <option value="Canada">Canada</option>
                <option value="India">India</option>
                <option value="UK">UK</option>
              </Field>
              <ErrorMessage
                name="country"
                component="div"
                style={{ color: "red" }}
              />
            </div>

            {/* Gender Dropdown */}
            <div>
              <label htmlFor="gender">Gender</label>
              <Field as="select" name="gender" id="gender">
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Field>
              <ErrorMessage
                name="gender"
                component="div"
                style={{ color: "red" }}
              />
            </div>

            <div className="form-group">
              <label>Preference</label>
              <div role="group" aria-labelledby="preference">
                <div>
                  <label>
                    <Field type="radio" name="preference" value="option1" />
                    Option 1
                  </label>
                </div>
                <div>
                  <label>
                    <Field type="radio" name="preference" value="option2" />
                    Option 2
                  </label>
                </div>
                <div>
                  <label>
                    <Field type="radio" name="preference" value="option3" />
                    Option 3
                  </label>
                </div>
                <div>
                  <label>
                    <Field type="radio" name="preference" value="option4" />
                    Option 4
                  </label>
                </div>
              </div>
              <ErrorMessage
                name="preference"
                component="div"
                className="error-message"
                style={{ color: "red" }}
              />
            </div>

            {/* Submit Button */}
            <div>
              <button type="submit">Submit</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Formdata;
