import React, { useState, useEffect } from "react";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { Collapse } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Progressbar from "./Progressbar";

function Petitiondetail() {
  const [secOneOpen, setSectionOne] = useState(false);
  const [secTwoOpen, setSectionTwo] = useState(false);
  const [secThreeOpen, setSectionThree] = useState(false);
  const [secFourthOpen, setSectionFourth] = useState(false);
  const [count, setCount] = useState(0);
  const [target, setTarget] = useState(1000);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  //   useEffect(() => {
  //     getDataCount();
  //   }, []);

  //   const getDataCount = async () => {
  //     setLoading(true);
  //     setError("");
  //     try {
  //       const querySnapshot = await getDocs(collection(db, "users")); // Replace "users" with your collection name
  //       const docCount = querySnapshot.size; // Get the count of documents
  //       setCount(docCount);
  //       setProgress((docCount / target) * 100);
  //     } catch (err) {
  //       console.error("Error counting documents:", err);
  //       setError("Failed to fetch data count.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  return (
    <>
      {/* <!-- Petition Text Section --> */}
      <div class="container-fluid petitiontext">
        <div class="row petitionrow">
          <div class="col-lg-8 col-sm-12 maintextsec">
            <div className="homepgcontent">
              <p class="text1">
                {" "}
                BREAK THE SILENCE
                <br />
                STAND IN SOLIDARITY
                <br />
                TAKE PERSONAL AND COLLECTIVE ACTION
              </p>
              <p class="text2">
                <span>Enough is enough:</span> A Plea from the
                World’s Humanitarian Community
              </p>

              <p class="detailtext">
                We, the undersigned - members of the world’s United Nations,
                humanitarian, health and aid community - urgently call on all UN
                Member States to fulfill their obligations under international
                law, and use all legal means available to protect the basic
                principles of humanity that continue to be disregarded in Israel’s
                unrelenting war on the civilians of Gaza and those trying to help
                them.
                <br />
                <br />
                We unequivocally condemn the continuing context of occupation, apartheid and oppression of the Palestinian people which, until addressed, renders both justice and peace impossible. We equally condemn the attacks of October 7th. We grieve all innocent lives lost and hope for the safe return of all those held hostage and detained. Any attack on civilians is both illegal and morally reprehensible - which is why there can be no justification for what has followed.
                <br />
                <br />
                What the world has witnessed since is the infliction of an
                unimaginable level of human suffering - beyond any sense of
                proportionality or necessity, and enabled by continuous,
                documented violations of international law that have been allowed
                to proceed virtually unchecked. More than 10 months ago, the
                International Court of Justice deemed that Israel’s military actions in
                Gaza constituted plausible violations of the Genocide Convention.
                This should have resulted in immediate, decisive, and united
                international action to ensure the respect of basic norms.
                Instead, each day since, the violence - and the violations - have
                only escalated.
                <br />
                <br />
                “Hell on earth.” “A death zone.” “A graveyard for children.” These
                are the words UN agencies have used to describe the situation in
                Gaza. In the words of the UN Secretary-General, “Nowhere is safe.
                Everywhere is a potential killing zone.” That includes homes,
                hospitals, schools, refugee camps, markets, UN buildings and posts
                - not only Gaza, but now in neighboring countries like Lebanon.
                And no one is safe. Tens of thousands of innocent civilians have
                been killed, detained, tortured, starved, deprived of their
                fundamental human rights, and subject to nightmarish conditions.
                This includes, at a shocking and unprecedented scale, babies,
                children, the elderly, the sick and injured, doctors, nurses,
                paramedics, medical students, journalists, aid workers, UN staff -
                all treated as acceptable targets. For many of these groups, this
                represents the highest death toll in any single conflict.
                <br />
                <br />
                Access to food, water, health, humanitarian aid has been
                restricted and undermined, and in many cases cut off entirely -
                yet again in contravention to international humanitarian law
                (IHL). Nearly the entire population of Gaza has been displaced,
                and most shelter has been destroyed. In yet another act of
                collective punishment, Israel has moved to ban UNRWA, a UN agency
                established in 1948 and that implements a mandate conferred by the
                UN General Assembly. UN and humanitarian agencies, as well as the
                UN Security Council, have been clear that there is “no
                alternative” to UNRWA, which represents an “irreplaceable
                life-line to the Palestinian people,” and that, due to the current
                acute humanitarian crisis, UNRWA is more needed than ever.
                <br />
                <br />
                As members of the humanitarian, medical and United Nations
                community, we are tasked with safeguarding the basic principles of
                humanity. Respect for international humanitarian law (IHL) is
                essential to our ability to undertake that duty.
                <br />
                <br />
                In that context, witnessing brutal, relentless, and illegal
                violence be perpetrated in full view of the world, entirely
                unchecked, is deeply disturbing. Watching egregious violations of
                international humanitarian law take place with impunity, and the
                total obliteration of the basic rights and protections of millions
                of people, is profoundly and personally destabilizing. Seeing UN
                staff and health, humanitarian and aid workers be treated as
                acceptable targets is utterly intolerable.
                <br />
                <br />
                These actions all add up to one thing: the systematic and
                deliberate deprivation of all life-sustaining conditions for the
                people of Gaza. Human beings are being killed, starved, displaced,
                detained, humiliated, tortured, and forced to live a horrific
                reality with no means of escape. The same treatment is being
                inflicted upon all those brave enough to try and help them,
                denying even the smallest measure of hope. This is happening with
                little consequence, no end in sight, and nothing close to the
                outrage or attention it warrants.
                <br />
                <br />
                That is dehumanization on a staggering scale, enabled by our political systems and the media. It should be a
                warning sign to us all.
                <br />
                <br />
                By signing this petition, we, as members of the world’s United
                Nations, humanitarian, health, and aid community, uphold our
                profound moral and ethical responsibility to declare without
                hesitation:
                <br />
                <br />

                <span>
                  <ul className="highlightedtxt">
                    <li>We stand in unwavering solidarity with our brave colleagues, the
                      people they are trying to help, and the principles they are trying
                      to safeguard.</li>
                    <br />
                    <li>   We will not be silent.</li>
                    <br />
                    <li>Nothing can justify these atrocities.</li>
                    <br />
                    <li>For the sake of our collective humanity, they cannot be
                      normalised.</li>
                    <br />
                    <li> There can be no double standards. All life must be valued equally.
                      All laws must be respected equally.</li>
                    <br />
                    <li>The world must say enough is enough.</li>
                  </ul>
                </span>
              </p>
            </div>

          </div>

          <div class="col-lg-4  col-sm-12 progressfieldsec">
            <div className="rightsidesec">
              {/* <!-- Progress Section --> */}

              <Progressbar />
              {/* <div class="container progresscontainer">
              <div class="progress" style={{ height: "20px" }}>
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{ width: progress + '%' }}
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progresstext">
                <p class="font-weight-bold sign">
                  <span>{count}</span> <br /> Signatures
                </p>
                <p class="font-weight-bold goal">
                  <span> {target}</span>
                  <br /> Next Goal
                </p>
              </div>

              <button class="btn btn-primary mt-3 supportbtn">
                <i class="fa-solid fa-hand-fist"></i>Support now
              </button>
            </div> */}

              <div class="formsection">
                <h3>Sign Now</h3>
                {/* <!-- <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">First Name</label>
                        <input type="email" class="form-control" id="exampleFormControlInput1">
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Last Name</label>
                        <input type="email" class="form-control" id="exampleFormControlInput1">
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Email</label>
                        <input type="email" class="form-control" id="exampleFormControlInput1"
                            placeholder="name@example.com">
                    </div> --> */}
              </div>
              {/* <!-- 
                <div class="radiosection">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                        <label class="form-check-label" for="flexRadioDefault1">
                            Yes!Tell me if this petition wins,and how can I help other relevant petitions
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                        <label class="form-check-label" for="flexRadioDefault1">
                            No.I don't want to hear about this petition's progress or other relevant petitions.
                        </label>
                    </div>
                </div> --> */}

              <button class="btn btn-primary mt-3 signbtn">
                <div href="sign.html" onClick={() => navigate("/signpetition")}>
                  Click here to sign this petition
                </div>
              </button>

              <div class="bluecontainer first">
                <div class="bluepatchone">
                  <h5 class="whotext">WHO SHOULD SIGN</h5>
                  <ul>
                    <li>
                      All international civil servants, including UN staff and
                      employees
                    </li>
                    <br />
                    <li>
                      Anyone currently studying, working or retired, engaged in any
                      aid, humanitarian or health/healthcare field
                    </li>
                  </ul>
                  <div class="designsec">
                    <button class="btn btn-primary mt-3 designbtn">
                      Download shareable flyer
                    </button>
                    <i class="fa-regular fa-hand-pointer"></i>
                  </div>
                </div>

                <div class="actionsec mobileview" style={{ display: "none" }}>
                  <h2>OUR CALL TO ACTION</h2>
                  <p class="actionpara1">
                    <span>
                      WE REMIND all UN Member States, in particular members of the UN
                      Security Council as well as Member States providing financial,
                      political and material support for the ongoing violations of
                      international law, of their duties under international law,
                    </span>
                    including:
                  </p>
                  <div class="readmore">
                    <p class="d-flex gap-1">
                      <button
                        class="btn btn-primary readmoreBtn"
                        onClick={() => setSectionOne(!secOneOpen)}
                      >
                        Read more
                      </button>
                    </p>
                    <Collapse in={secOneOpen}>
                      <div class="collapse">
                        <div class="card card-body">
                          <ul>
                            <li>
                              Compliance with the principles and purposes of the{" "}
                              <strong> UN Charter</strong>, and the obligations therein,
                              which include maintaining international peace and
                              security, and taking effective collective measures for the
                              prevention of threats to peace.
                            </li>
                            <li>
                              The obligation under common article 1 of the{" "}
                              <strong>Geneva Conventions</strong> to ensure respect for
                              international humanitarian law by all State parties, which
                              include the State of Israel and the State of Palestine.
                              The basic principles of IHL include the distinction
                              between civilians and combatants; prohibition of attacks
                              against those not involved in combat; prohibition on the
                              infliction of unnecessary suffering; the principle of
                              proportionality; the notion of necessity; and a shared
                              understanding of and respect for humanity, even in
                              conflict.
                            </li>
                            <li>
                              Obligations under the <strong>Genocide Convention</strong>
                              , a treaty established after the Holocaust to punish both
                              genocide as well as complicity in genocide.{" "}
                            </li>
                            <li>
                              For the majority of Member States, obligations under the{" "}
                              <strong> UN Convention Against Torture</strong>, and for
                              all Member States the resulting customary international
                              law principle on the absolute prohibition of torture.
                            </li>
                            <li>
                              Obligations under the{" "}
                              <strong>
                                International Convention on the Elimination of All Forms
                                of Racial Discrimination (ICERD)
                              </strong>
                              , including Article 3 which obligates governments to
                              prevent, prohibit, and eradicate all racial segregation
                              and apartheid.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <p class="actionpara2">
                    WE URGE all UN Member States to call for, and take meaningful action
                    - using all legal and diplomatic means at their disposal - to:
                  </p>
                  <div class="readmore">
                    <p class="d-flex gap-1">
                      <button
                        class="btn btn-primary readmoreBtn"
                        onClick={() => setSectionTwo(!secTwoOpen)}
                      >
                        Read more
                      </button>
                    </p>
                    <Collapse in={secTwoOpen}>
                      <div
                        class="collapse"
                        id="collapseExample1"
                        data-bs-parent=".actionsec"
                      >
                        <div class="card card-body">
                          <ul>
                            <li>
                              <strong>
                                Achieve an immediate and unconditional humanitarian
                                ceasefire in Gaza and Lebanon.
                              </strong>
                            </li>
                            <ul>
                              <li>
                                For this to be effective, the ceasefire must ensure
                                protection of hospitals, patients, health professionals
                                and humanitarian workers.{" "}
                              </li>
                              <li>
                                {" "}
                                It must include safe and unhindered humanitarian access
                                for workers and life-sustaining resources to all areas
                                to reach affected populations, wherever they are.{" "}
                              </li>
                              <li>
                                Patients and the wounded must be allowed to evacuate.{" "}
                              </li>
                              <li>
                                All access points must be fully opened to allow the
                                continuous flow of essential supplies, including
                                adequate fuel and materials to restore critical
                                infrastructure and support commercial activities.{" "}
                              </li>
                              <li>
                                Reliable telecommunications networks must be established
                                to facilitate effective coordination of response
                                efforts.
                              </li>
                            </ul>
                            <br />
                            <li>
                              <strong>
                                {" "}
                                Secure the urgent release of all those held hostage
                                and/or illegally detained,
                              </strong>
                              with all parties held responsible for a transparent
                              accounting of names, whereabouts, and condition, ensured
                              access for the International Committee of the Red Cross
                              (ICRC), and adherence to due process standards{" "}
                            </li>
                            <br />
                            <li>
                              <strong>Impose a full arms embargo on Israel, </strong>
                              halting all arms agreements, imports, exports and
                              transfers.{" "}
                            </li>
                            <br />
                            <li>
                              <strong>
                                {" "}
                                Immediately cease all other methods of aiding or
                                assisting in the commission of any violations{" "}
                              </strong>
                              of international law.{" "}
                            </li>
                            <br />
                            <li>
                              <strong>
                                Establish a transparent framework to identify, record
                                and evaluate all violations committed, including
                                genocide, along with measures to ensure accountability{" "}
                              </strong>
                              for all alleged perpetrators of international crimes,
                              grave human rights violations and abuses in Israel and the
                              Occupied Palestinian Territory.
                            </li>
                            <br />
                            <li>
                              <strong>
                                {" "}
                                Support the International Court of Justice advisory
                                opinion{" "}
                              </strong>
                              by acknowledging and addressing the illegality of Israel’s
                              occupation of Palestinian territories, including the
                              on-going crimes of racial discrimination and apartheid and
                              the long-term deprivation of the Palestinian people’s
                              right to self-determination. This must include refusing to
                              render aid or assistance in maintaining the occupation,
                              and ensuring reparations are made.{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <p class="actionpara1">
                    <span>
                      {" "}
                      WE CALL on Israel to uphold its obligations under international
                      law and the treaties it has ratified,
                    </span>{" "}
                    including the UN Charter, the Geneva Conventions, the Convention on
                    the Privileges and Immunities of the United Nations, the Convention
                    on the Rights of the Child, the International Covenant on Civil and
                    Political Rights, and the International Convention on the
                    Elimination of All Forms of Racial Discrimination, and:{" "}
                  </p>
                  <div class="readmore">
                    <p class="d-flex gap-1">
                      <button
                        class="btn btn-primary readmoreBtn"
                        onClick={() => setSectionThree(!secThreeOpen)}
                      >
                        Read more
                      </button>
                    </p>
                    <Collapse in={secThreeOpen}>
                      <div
                        class="collapse"
                        id="collapseExample2"
                        data-bs-parent=".actionsec"
                      >
                        <div class="card card-body">
                          <ul>
                            <li>
                              <strong>
                                {" "}
                                Enact an immediate and unconditional ceasefire{" "}
                              </strong>
                              that meets the conditions outlined above
                            </li>
                            <br />

                            <li>
                              <strong>
                                Distinguish between civilians and combatants{" "}
                              </strong>
                              in all hostilities{" "}
                            </li>
                            <br />

                            <li>
                              <strong>
                                {" "}
                                Cease the infliction of unnecessary, disproportionate
                                and collective suffering
                              </strong>
                            </li>
                            <br />

                            <li>
                              <strong>
                                {" "}
                                Cease attacks on persons with protected status,{" "}
                              </strong>
                              including health care workers, journalists, women,
                              children, the sick and wounded, refugees, and the
                              displaced.
                            </li>
                            <br />

                            <li>
                              <strong>
                                {" "}
                                Cease targeting of medical facilities, staff and
                                vehicles, and humanitarian efforts{" "}
                              </strong>
                            </li>
                            <br />

                            <li>
                              <strong>
                                {" "}
                                Facilitate immediate access to humanitarian relief, and
                                the duty to assist the UN in the fulfillment of its
                                duties,
                              </strong>
                              including by recognizing the status of all UN agencies
                              including UNRWA{" "}
                            </li>
                            <br />

                            <li>
                              <strong>
                                {" "}
                                Facilitate the medical evacuation of all sick and
                                wounded persons from Gaza,
                              </strong>
                              , including by recognizing the status of all UN agencies
                              including UNRWA{" "}
                            </li>
                            <br />

                            <li>
                              <strong>
                                {" "}
                                Facilitate the medical evacuation of all sick and
                                wounded persons from Gaza,
                              </strong>
                              in particular children, along with their guardians
                            </li>
                            <br />

                            <li>
                              <strong>
                                {" "}
                                Cease the arbitrary and unlawful detention of civilians,
                                including children and medical professionals
                              </strong>
                            </li>
                            <br />

                            <li>
                              <strong>
                                {" "}
                                Cease the torture, and cruel, inhuman and degrading
                                treatment of detainees, including the perpetration of
                                rape and other forms of sexual and gender-based violence
                                in detention -{" "}
                              </strong>
                              recognizing the absolute prohibition on these actions, and
                              adhering to norms for the treatment of detainees and
                              prisoners of war, including the right to due process
                            </li>
                            <br />
                            <li>
                              <strong>
                                {" "}
                                Recognize that every human being, including every child,
                                has the inviolable right to life, as well as the right
                                to self-determination
                              </strong>
                            </li>
                            <br />
                            <li>
                              <strong>
                                {" "}
                                Recognize the international prohibition on racial
                                discrimination, segregation and apartheid{" "}
                              </strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <p class="actionpara2">
                    LASTLY, WE CALL ON all institutions and individuals involved in aid,
                    humanitarian and health-related work to:
                  </p>
                  <div class="readmore">
                    <p class="d-flex gap-1">
                      <button
                        class="btn btn-primary readmoreBtn"
                        onClick={() => setSectionFourth(!secFourthOpen)}
                      >
                        Read more
                      </button>
                    </p>
                    <Collapse in={secFourthOpen}>
                      <div
                        class="collapse"
                        id="collapseExample3"
                        data-bs-parent=".actionsec"
                      >
                        <div class="card card-body">
                          <ul>
                            <li>
                              <strong> Break the silence</strong>
                            </li>
                            <br />

                            <li>
                              <strong> Stand in solidarity </strong>with our colleagues
                              and those they are trying to help
                            </li>
                            <br />

                            <li>
                              <strong> Take personal and collective action </strong>to
                              uphold the basic principles of humanity we are all
                              committed to safeguard - and which in turn safeguard us
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>

                <div class="bluepatchtwo">
                  <h5>IN NUMBERS</h5>
                  {/* <p class="description">
                  As of the end of October 2024, over 43,061 women, men, and
                  children have been killed in the Gaza strip —including 13,319
                  children, 786 of whom were under the age of one.
                  <br />
                  <br />
                  The war has resulted in the tragic and unprecedented loss of
                  over 322 aid workers, including 237 UN staff, 168 journalists
                  and media workers, and more than 1047 healthcare workers,
                  marking the highest death toll for each group in any single
                  crisis on record.
                  <br />
                  <br />
                  <span class="source"> Source </span>{" "}
                  <span class="italictext">
                    (including more information on impacts crisis): UN
                    Office for the Coordination of Humanitarian Affairs (OCHA){" "}

                    Impact Snapshot - 19 Nov 2024 /

                    <Link
                      to="https://www.ochaopt.org/content/reported-impact-snapshot-gaza-strip-19-november-2024"
                      target="_blank">

                      Latest figures are available here
                    </Link>
                  </span>
                </p> */}

                  <div class="lossdiv">
                    <div class="blueheader">Civilians killed in Gaza strip</div>
                    <p class="figures">
                      {" "}
                      <p className="asoftext">As of 19 November 2024</p>
                      <ul>
                        <li><span>43 972 </span>(men, women & children)</li>
                        <li><span>13,319 </span>children</li>
                        <li><span>70% </span>of verified deaths were women and children</li>
                      </ul>


                    </p>

                    <div class="blueheader">Humanitarian and health workers killed</div>
                    <p className="asoftext">As of 19 November 2024</p>
                    <ul class="figuredetails">
                      <li> 1,047 Health care workers</li>
                      <li>333 Aid workers</li>
                      <li>247 UN staff</li>
                      <li> 180 Journalists</li>

                    </ul>
                  </div>
                  <span class="source"> Source </span>{" "}
                  <span class="italictext">
                    (including more information on impacts crisis): UN
                    Office for the Coordination of Humanitarian Affairs (OCHA){" "}

                    <Link to="https://www.ochaopt.org/content/reported-impact-snapshot-gaza-strip-19-november-2024" target="_blank">
                      Impact Snapshot - 19 Nov 2024 &nbsp;
                    </Link>
                    <br />
                    <span className="latestread">Read the latest from OCHA on the impact of the crisis &nbsp;</span>
                    <Link
                      to="https://www.ochaopt.org/"
                      target="_blank">

                      here.
                    </Link>
                  </span>
                </div>


                <div className="bluepatchthree">
                  <Link to="/readingmaterial#readsection"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <p className="furtherread">
                      <span> FURTHER READING</span>
                    </p>
                    <ul className="furtherreadpoints">
                      <li>Statements from UN and other international organisations</li>

                      <li>Testimonials from healthcare workers around the world </li>

                      <li>Treaties</li>

                      <li>UN Reports</li>

                      <li>International Court of justice</li>

                    </ul>
                  </Link>
                </div>

              </div>
            </div>

          </div>
        </div>

        {/* <!--Our Call section--> */}

        <div class="actionsec">
          <h2>OUR CALL TO ACTION</h2>
          <p class="actionpara1">
            <span>
              WE REMIND all UN Member States, in particular members of the UN
              Security Council as well as Member States providing financial,
              political and material support for the ongoing violations of
              international law, of their duties under international law,
            </span>
            including:
          </p>
          <div class="readmore">
            <p class="d-flex gap-1">
              <button
                class="btn btn-primary readmoreBtn"
                onClick={() => setSectionOne(!secOneOpen)}
              >
                Read more
              </button>
            </p>
            <Collapse in={secOneOpen}>
              <div class="collapse">
                <div class="card card-body">
                  <ul>
                    <li>
                      Compliance with the principles and purposes of the{" "}
                      <strong> UN Charter</strong>, and the obligations therein,
                      which include maintaining international peace and
                      security, and taking effective collective measures for the
                      prevention of threats to peace.
                    </li>
                    <li>
                      The obligation under common article 1 of the{" "}
                      <strong>Geneva Conventions</strong> to ensure respect for
                      international humanitarian law by all State parties, which
                      include the State of Israel and the State of Palestine.
                      The basic principles of IHL include the distinction
                      between civilians and combatants; prohibition of attacks
                      against those not involved in combat; prohibition on the
                      infliction of unnecessary suffering; the principle of
                      proportionality; the notion of necessity; and a shared
                      understanding of and respect for humanity, even in
                      conflict.
                    </li>
                    <li>
                      Obligations under the <strong>Genocide Convention</strong>
                      , a treaty established after the Holocaust to punish both
                      genocide as well as complicity in genocide.{" "}
                    </li>
                    <li>
                      For the majority of Member States, obligations under the{" "}
                      <strong> UN Convention Against Torture</strong>, and for
                      all Member States the resulting customary international
                      law principle on the absolute prohibition of torture.
                    </li>
                    <li>
                      Obligations under the{" "}
                      <strong>
                        International Convention on the Elimination of All Forms
                        of Racial Discrimination (ICERD)
                      </strong>
                      , including Article 3 which obligates governments to
                      prevent, prohibit, and eradicate all racial segregation
                      and apartheid.
                    </li>
                  </ul>
                </div>
              </div>
            </Collapse>
          </div>
          <p class="actionpara2">
            WE URGE all UN Member States to call for, and take meaningful action
            - using all legal and diplomatic means at their disposal - to:
          </p>
          <div class="readmore">
            <p class="d-flex gap-1">
              <button
                class="btn btn-primary readmoreBtn"
                onClick={() => setSectionTwo(!secTwoOpen)}
              >
                Read more
              </button>
            </p>
            <Collapse in={secTwoOpen}>
              <div
                class="collapse"
                id="collapseExample1"
                data-bs-parent=".actionsec"
              >
                <div class="card card-body">
                  <ul>
                    <li>
                      <strong>
                        Achieve an immediate and unconditional humanitarian
                        ceasefire in Gaza and Lebanon.
                      </strong>
                    </li>
                    <ul>
                      <li>
                        For this to be effective, the ceasefire must ensure
                        protection of hospitals, patients, health professionals
                        and humanitarian workers.{" "}
                      </li>
                      <li>
                        {" "}
                        It must include safe and unhindered humanitarian access
                        for workers and life-sustaining resources to all areas
                        to reach affected populations, wherever they are.{" "}
                      </li>
                      <li>
                        Patients and the wounded must be allowed to evacuate.{" "}
                      </li>
                      <li>
                        All access points must be fully opened to allow the
                        continuous flow of essential supplies, including
                        adequate fuel and materials to restore critical
                        infrastructure and support commercial activities.{" "}
                      </li>
                      <li>
                        Reliable telecommunications networks must be established
                        to facilitate effective coordination of response
                        efforts.
                      </li>
                    </ul>
                    <br />
                    <li>
                      <strong>
                        {" "}
                        Secure the urgent release of all those held hostage
                        and/or illegally detained,
                      </strong>
                      with all parties held responsible for a transparent
                      accounting of names, whereabouts, and condition, ensured
                      access for the International Committee of the Red Cross
                      (ICRC), and adherence to due process standards{" "}
                    </li>
                    <br />
                    <li>
                      <strong>Impose a full arms embargo on Israel, </strong>
                      halting all arms agreements, imports, exports and
                      transfers.{" "}
                    </li>
                    <br />
                    <li>
                      <strong>
                        {" "}
                        Immediately cease all other methods of aiding or
                        assisting in the commission of any violations{" "}
                      </strong>
                      of international law.{" "}
                    </li>
                    <br />
                    <li>
                      <strong>
                        Establish a transparent framework to identify, record
                        and evaluate all violations committed, including
                        genocide, along with measures to ensure accountability{" "}
                      </strong>
                      for all alleged perpetrators of international crimes,
                      grave human rights violations and abuses in Israel and the
                      Occupied Palestinian Territory.
                    </li>
                    <br />
                    <li>
                      <strong>
                        {" "}
                        Support the International Court of Justice advisory
                        opinion{" "}
                      </strong>
                      by acknowledging and addressing the illegality of Israel’s
                      occupation of Palestinian territories, including the
                      on-going crimes of racial discrimination and apartheid and
                      the long-term deprivation of the Palestinian people’s
                      right to self-determination. This must include refusing to
                      render aid or assistance in maintaining the occupation,
                      and ensuring reparations are made.{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </Collapse>
          </div>
          <p class="actionpara1">
            <span>
              {" "}
              WE CALL on Israel to uphold its obligations under international
              law and the treaties it has ratified,
            </span>{" "}
            including the UN Charter, the Geneva Conventions, the Convention on
            the Privileges and Immunities of the United Nations, the Convention
            on the Rights of the Child, the International Covenant on Civil and
            Political Rights, and the International Convention on the
            Elimination of All Forms of Racial Discrimination, and:{" "}
          </p>
          <div class="readmore">
            <p class="d-flex gap-1">
              <button
                class="btn btn-primary readmoreBtn"
                onClick={() => setSectionThree(!secThreeOpen)}
              >
                Read more
              </button>
            </p>
            <Collapse in={secThreeOpen}>
              <div
                class="collapse"
                id="collapseExample2"
                data-bs-parent=".actionsec"
              >
                <div class="card card-body">
                  <ul>
                    <li>
                      <strong>
                        {" "}
                        Enact an immediate and unconditional ceasefire{" "}
                      </strong>
                      that meets the conditions outlined above
                    </li>
                    <br />

                    <li>
                      <strong>
                        Distinguish between civilians and combatants{" "}
                      </strong>
                      in all hostilities{" "}
                    </li>
                    <br />

                    <li>
                      <strong>
                        {" "}
                        Cease the infliction of unnecessary, disproportionate
                        and collective suffering
                      </strong>
                    </li>
                    <br />

                    <li>
                      <strong>
                        {" "}
                        Cease attacks on persons with protected status,{" "}
                      </strong>
                      including health care workers, journalists, women,
                      children, the sick and wounded, refugees, and the
                      displaced.
                    </li>
                    <br />

                    <li>
                      <strong>
                        {" "}
                        Cease targeting of medical facilities, staff and
                        vehicles, and humanitarian efforts{" "}
                      </strong>
                    </li>
                    <br />

                    <li>
                      <strong>
                        {" "}
                        Facilitate immediate access to humanitarian relief, and
                        the duty to assist the UN in the fulfillment of its
                        duties,
                      </strong>
                      including by recognizing the status of all UN agencies
                      including UNRWA{" "}
                    </li>
                    <br />

                    <li>
                      <strong>
                        {" "}
                        Facilitate the medical evacuation of all sick and
                        wounded persons from Gaza,
                      </strong>
                      , including by recognizing the status of all UN agencies
                      including UNRWA{" "}
                    </li>
                    <br />

                    <li>
                      <strong>
                        {" "}
                        Facilitate the medical evacuation of all sick and
                        wounded persons from Gaza,
                      </strong>
                      in particular children, along with their guardians
                    </li>
                    <br />

                    <li>
                      <strong>
                        {" "}
                        Cease the arbitrary and unlawful detention of civilians,
                        including children and medical professionals
                      </strong>
                    </li>
                    <br />

                    <li>
                      <strong>
                        {" "}
                        Cease the torture, and cruel, inhuman and degrading
                        treatment of detainees, including the perpetration of
                        rape and other forms of sexual and gender-based violence
                        in detention -{" "}
                      </strong>
                      recognizing the absolute prohibition on these actions, and
                      adhering to norms for the treatment of detainees and
                      prisoners of war, including the right to due process
                    </li>
                    <br />
                    <li>
                      <strong>
                        {" "}
                        Recognize that every human being, including every child,
                        has the inviolable right to life, as well as the right
                        to self-determination
                      </strong>
                    </li>
                    <br />
                    <li>
                      <strong>
                        {" "}
                        Recognize the international prohibition on racial
                        discrimination, segregation and apartheid{" "}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </Collapse>
          </div>
          <p class="actionpara2">
            LASTLY, WE CALL ON all institutions and individuals involved in aid,
            humanitarian and health-related work to:
          </p>
          <div class="readmore">
            <p class="d-flex gap-1">
              <button
                class="btn btn-primary readmoreBtn"
                onClick={() => setSectionFourth(!secFourthOpen)}
              >
                Read more
              </button>
            </p>
            <Collapse in={secFourthOpen}>
              <div
                class="collapse"
                id="collapseExample3"
                data-bs-parent=".actionsec"
              >
                <div class="card card-body">
                  <ul>
                    <li>
                      <strong> Break the silence</strong>
                    </li>
                    <br />

                    <li>
                      <strong> Stand in solidarity </strong>with our colleagues
                      and those they are trying to help
                    </li>
                    <br />

                    <li>
                      <strong> Take personal and collective action </strong>to
                      uphold the basic principles of humanity we are all
                      committed to safeguard - and which in turn safeguard us
                    </li>
                  </ul>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
}

export default Petitiondetail;
