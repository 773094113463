import React, { useState, useEffect } from "react";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";

function Progressbar() {
  const [count, setCount] = useState(0);
  const [target, setTarget] = useState(1000);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    getDataCount();
  }, []);

  useEffect(() => {
    targetNumber(count);
  }, [count]);

  const getDataCount = async () => {
    setLoading(true);
    setError("");
    try {
      const querySnapshot = await getDocs(collection(db, "users")); // Replace "users" with your collection name
      const docCount = querySnapshot.size; // Get the count of documents
      setCount(docCount);
      // setProgress((docCount / target) * 100);
    } catch (err) {
      console.error("Error counting documents:", err);
      setError("Failed to fetch data count.");
    } finally {
      setLoading(false);
    }
  };

  const targetNumber = (currentNum) => {
    let goalArray = [250000, 500000, 1000000];
    let currentNumber = currentNum;
    let target;

    for (let i = 0; i < goalArray.length; i++) {
      if (i != goalArray.length - 1) {
        if (currentNumber < goalArray[i]) {
          target = goalArray[i];
          break; // Exit the current iteration of the loop
        }
        if (currentNumber == goalArray[i]) {
          target = goalArray[i] * 2;
        }
      } else {
        target = goalArray[goalArray.length - 1];
      }
    }
    setProgress((count / target) * 100);
    setTarget(target);
    return target;
  };

  return (
    <div class="container progresscontainer">
      <div class="progress" style={{ height: "20px" }}>
        <div
          class="progress-bar"
          role="progressbar"
          style={{ width: progress + "%" }}
          aria-valuenow="70"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div class="progresstext">
        <p class="font-weight-bold sign">
          <span>{count}</span> <br /> Signatures
        </p>
        <p class="font-weight-bold goal">
          <span>{target}</span>
          <br /> Next Goal
        </p>
      </div>

      <p className="ourgoal">Our Goal is to Reach 1 Million</p>

      <button class="btn btn-primary mt-3 supportbtn">
        <i class="fa-solid fa-hand-fist"></i>Support now
      </button>
    </div>
  );
}

export default Progressbar;
