import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ShareButtons from "./ShareButtons";
// import { imgurl } from "../../public/ImageUrl";

function Header() {
  const navigate = useNavigate();

  return (
    <>
      <nav class="navbar navbar-expand-lg header-navbar">
        <div class="navbar-brand" href="#">
          <img src="/assests/images/Logo.png" alt="Logo" />
        </div>
        <ShareButtons></ShareButtons>
      </nav>

      {/* <!-- Orange Navbar with Navigation Links --> */}
      <div class="navbar navbar-expand-lg orange-bar">
        <div class="navbar-nav mx-auto orangenavsec">
          <div
            class="nav-item nav-link pagelink"
            onClick={() => navigate("/petitiondetail")}
          >
            PETITION DETAILS
          </div>
          <div
            class="nav-item nav-link pagelink"
            onClick={() => navigate("/readingmaterial")}
          >
            READING MATERIAL
          </div>
          <div
            class="nav-item nav-link pagelink"
            onClick={() => navigate("/signpetition")}
          >
            SIGN PETITION
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
