import React from "react";

function Banner() {
  return (
    //  <!-- Banner Section -->
    <div class="banner">
      {/* <!-- <img src="assets/images/Background.png" alt="Banner Background"> --> */}
      {/* <!-- <div class="banner-text">#ENOUGHISENOUGH</div> --> */}
    </div>
  );
}

export default Banner;
