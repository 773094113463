// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyAP1R4vt4LDa-MLKJj9GqPnY9h5WkXsG9E",
//   authDomain: "ghana-petition.firebaseapp.com",
//   projectId: "ghana-petition",
//   storageBucket: "ghana-petition.firebasestorage.app",
//   messagingSenderId: "664837158601",
//   appId: "1:664837158601:web:c3ae9dff223f199f317f18",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCXBvD3vcVddgFHrkSG9khw2JqI9R3WDJQ",
  authDomain: "ghanapetition.firebaseapp.com",
  projectId: "ghanapetition",
  storageBucket: "ghanapetition.firebasestorage.app",
  messagingSenderId: "241555853655",
  appId: "1:241555853655:web:45fe628006ca6a10f02e12",
  measurementId: "G-QQ5DS8D3RT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
