import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Progressbar from "./Progressbar";

function Readingmaterial() {
  const navigate = useNavigate();
  return (
    // <!-- Petition Text Section -->
    <div class="container-fluid petitiontext" id="readsection">
      <div class="row petitionrow furthersec">
        <div class="col-lg-4  col-sm-12 progressfieldsec">
          {/* <!-- Progress Section --> */}
          {/* <div class="container progresscontainer">
            <div class="progress" style={{ height: "20px" }}>
              <div
                class="progress-bar"
                role="progressbar"
                style={{ width: "70%" }}
                aria-valuenow="70"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div class="progresstext">
              <p class="font-weight-bold sign">
                <span>10,596</span> <br /> Signatures
              </p>
              <p class="font-weight-bold goal">
                <span> 1M </span>
                <br /> Next Goal
              </p>
            </div>

            <button class="btn btn-primary mt-3 supportbtn">
              <i class="fa-solid fa-hand-fist"></i>Support now
            </button>
          </div> */}
          <Progressbar />

          <div class="formsection">
            <h3>Sign Now</h3>

            <button class="btn btn-primary mt-3 signbtn">
              <div href="sign.html" onClick={() => navigate("/signpetition")}>
                Click here to sign this petition
              </div>
            </button>
          </div>
        </div>

        <div class="col-lg-12 col-sm-12 maintextsec">
          <h2 class="readingtxt">FURTHER READING</h2>

          <p class="detailtext">
            <span>
              Statements from UN and other international organisations{" "}
            </span>
            (non-exhaustive)
            <ul class="detailedlists">
              <li>
                UNICEF:{" "}
                <Link to="https://www.unicef.org/press-releases/gaza-has-become-graveyard-thousands-children">
                  “Gaza has become a graveyard for thousands of children”
                </Link>
                (Oct 2023) /{" "}
                <Link
                  to="https://www.unicef.org/press-releases/gazas-children-trapped-cycle-pain"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Gaza is the real-world embodiment of hell on earth for its
                  one million children.”{" "}
                </Link>
                (Oct 2024){" "}
              </li>
              <li>
                WHO:
                <Link
                  to="https://www.who.int/director-general/speeches/detail/who-director-general-s-remarks-at-the-informal-plenary-meeting-of-the-united-nations-general-assembly---17-november-2023"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “We are witnessing the destruction of life and property on a
                  horrific scale.”{" "}
                </Link>{" "}
                (Nov 2023) /{" "}
                <Link
                  to="https://uk.news.yahoo.com/beyond-words-director-makes-tearful-114658481.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “I’m struggling to speak because the situation is beyond
                  words” - WHO Director makes tearful plea for Gaza ceasefire{" "}
                </Link>
                (Jan 2024) /{" "}
                <Link
                  to="https://news.un.org/en/story/2024/02/1146792"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Gaza has become a death zone”
                </Link>{" "}
                (Feb 2024) /{" "}
                <Link
                  to="https://www.bbc.com/news/world-middle-east-68471572"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Children starving to death in northern Gaza”{" "}
                </Link>
                (Mar 2024)
              </li>
              <li>
                Inter-Agency Standing Committee:{" "}
                <Link
                  to="https://www.unhcr.org/news/speeches-and-statements/civilians-gaza-extreme-peril-while-world-watches-ten-requirements"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Civilians in Gaza in extreme peril while the world watches
                  on”
                </Link>
                (Feb 2024) /{" "}
                <Link
                  to="https://www.un.org/unispal/document/statement-by-iasc-23sept24/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  “The number of aid workers killed in Gaza in the past year is
                  the highest ever in a single crisis.”{" "}
                </Link>
                (Sep 2024) /{" "}
                <Link
                  to="https://interagencystandingcommittee.org/inter-agency-standing-committee/statement-principals-inter-agency-standing-committee-stop-assault-palestinians-gaza-and-those-trying"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Stop the assault on Palestinians in Gaza and on those trying
                  to help them”{" "}
                </Link>
                (Oct 2024)
              </li>
              <li>
                UN Secretary General:{" "}
                <Link
                  to="https://press.un.org/en/2024/sgsm22310.doc.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Humanitarian situation in Gaza a moral stain on us all”{" "}
                </Link>{" "}
                (Jul 2024) /{" "}
                <Link
                  to="https://press.un.org/en/2024/sgsm22436.doc.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  “Journalists in Gaza have been killed at a level unseen in any
                  conflict in modern times.”{" "}
                </Link>
                (Nov 2024)
              </li>
              <li>
                OHCHR:{" "}
                <Link
                  to="https://www.un.org/unispal/document/statement-gaza-ohchr-25jun24/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Killing, detention and enforced disappearance of health
                  workers...has had a devastating impact”{" "}
                </Link>{" "}
                (Jun 2024) /{" "}
                <Link
                  to="https://www.ohchr.org/en/press-releases/2024/10/un-commission-finds-war-crimes-and-crimes-against-humanity-israeli-attacks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Israeli security forces have deliberately killed, detained
                  and tortured medical personnel and targeted medical vehicles”{" "}
                </Link>{" "}
                (Sep 2024)
              </li>
              <li>
                World Food Programme:{" "}
                <Link
                  to="https://www.wfp.org/stories/gaza-urgent-action-needed-hunger-soars-critical-levels"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “WFP warns famine could be a reality”{" "}
                </Link>{" "}
                (Oct 2024)
              </li>
              <li>
                OCHA:{" "}
                <Link
                  to="https://www.ohchr.org/en/press-releases/2024/05/onslaught-violence-against-women-and-children-gaza-unacceptable-un-experts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Violence against women and children in Gaza unacceptable”{" "}
                </Link>{" "}
                (Mar 2024){" "}
              </li>
              <li>
                UN Emergency Relief Coordinator:{" "}
                <Link
                  to="https://news.un.org/en/story/2024/10/1156171"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Such blatant disregard for basic humanity and for the laws of
                  war must stop.”{" "}
                </Link>
                (Oct 2024)
              </li>
              <li>
                IOM:{" "}
                <Link
                  to="https://www.iom.int/news/relief-efforts-gaza-near-collapse-ceasefire-urgently-needed-iom-chief"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Relief efforts in Gaza near collapse, ceasefire urgently
                  needed”{" "}
                </Link>{" "}
                (Dec 2023){" "}
              </li>
              <li>
                Special Rapporteur on the situation of human rights in the
                Palestinian territories:{" "}
                <Link
                  to="https://news.un.org/en/story/2024/03/1147976"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Reasonable grounds genocide is being committed in Gaza”{" "}
                </Link>{" "}
                (Mar 2024)
              </li>
              <li>
                Human Rights Watch:{" "}
                <Link
                  to="https://www.hrw.org/news/2024/08/26/israel-palestinian-healthcare-workers-tortured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Palestinian healthcare workers tortured”{" "}
                </Link>{" "}
                (August 2024)
              </li>
              <li>
                UN Women - Gaza:{" "}
                <Link
                  to="https://www.un.org/unispal/document/gaza-a-war-on-womens-health-unwomen-19sep24/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  A War on Women’s Health{" "}
                </Link>
                (Sep 2024)
              </li>
              <li>
                FAO -{" "}
                <Link
                  to="https://www.un.org/unispal/document/fao-press-release-17oct24/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “1.84 million people across the Gaza Strip are experiencing
                  extremely critical levels of acute food insecurity”{" "}
                </Link>
                (Oct 2024)
              </li>
              <li>
                UN Humanitarian Coordinator for oPt -{" "}
                <Link
                  to="https://news.un.org/en/story/2024/07/1152201"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “People try to sew together some plastic sheets to give
                  themselves some cover through the nights…there are 2 million
                  sad stories in Gaza.”{" "}
                </Link>
                (Jul 2024)
              </li>
              <li>
                Amnesty International -{" "}
                <Link
                  to="https://www.amnesty.org/en/latest/news/2024/07/israel-must-end-mass-incommunicado-detention-and-torture-of-palestinians-from-gaza/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Israel must end mass incommunicado detention and torture of
                  Palestinians from Gaza{" "}
                </Link>{" "}
                (Jul 2024)
              </li>
              <li>
                Committee to Protect Journalists -{" "}
                <Link
                  to="https://cpj.org/2024/11/journalist-casualties-in-the-israel-gaza-conflict/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Since the war in Gaza started, journalists have been paying
                  the highest price – their lives – for their reporting. Without
                  protection, equipment, international presence, communications,
                  or food and water, they are still doing their crucial jobs to
                  tell the world the truth”{" "}
                </Link>
                (Nov 2024)
              </li>
            </ul>
          </p>

          <p class="detailtext">
            <span>Testimonials from healthcare workers around the world </span>
            (non-exhaustive)
            <ul class="detailedlists">
              <li>
                {" "}
                <Link
                  to="https://www.nytimes.com/interactive/2024/10/09/opinion/gaza-doctor-interviews.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  65 Doctors, Nurses, Paramedics: What we saw in Gaza
                </Link>{" "}
                - New York Times (Oct 2024)
              </li>
              <li>
                {" "}
                <Link
                  to="https://www.theguardian.com/world/2024/apr/07/targeting-healthcare-workers-airstrikes-constant-fear-uk-doctors-gaza"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ‘They’re targeting healthcare workers’: UK doctors in Gaza
                </Link>{" "}
                - Guardian (Apr 2024)
              </li>
              <li>
                {" "}
                <Link
                  to="https://reliefweb.int/report/occupied-palestinian-territory/we-need-break-cry-no-respite-exhausted-gaza-healthcare-workers-after-300-days-israels-military-assault"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  "We need a break to cry" - No respite for exhausted Gaza
                  healthcare workers{" "}
                </Link>{" "}
                - Medical Aid for Palestinians (Aug 2024)
              </li>
              <li>
                {" "}
                <Link
                  to="https://www.hrw.org/video-photos/audio/2024/08/26/israel-tortures-healthcare-workers-daily-brief-august-26-2024"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Every minute we were beaten...They had lighters: one soldier
                  tried to burn me but burned the person next to me. I told them
                  I’m a doctor, but they didn’t care.”
                </Link>{" "}
                - Human Rights Watch (Aug 2024)
              </li>
              <li>
                {" "}
                <Link
                  to="https://www.theguardian.com/world/2024/apr/02/gaza-palestinian-children-killed-idf-israel-war"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “I saw even small children with direct sniper shot wounds to
                  the head as well as in the chest. They were not combatants,
                  they were small children.”{" "}
                </Link>{" "}
                - Guardian (Apr 2024)
              </li>
              <li>
                {" "}
                <Link
                  to="https://english.elpais.com/international/2024-07-10/i-feel-complicit-the-moral-dilemma-of-an-israeli-doctor-who-treated-gaza-prisoners-in-a-detention-and-torture-center.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “‘I feel complicit’: The moral dilemma of an Israeli doctor
                  who treated Gaza prisoners in a detention and torture center”{" "}
                </Link>
                - El Pais (Jul 2024)
              </li>
              <li>
                {" "}
                <Link
                  to="https://www.bbc.com/news/world-middle-east-68513408"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Gaza medics…were blindfolded, detained, forced to strip and
                  repeatedly beaten by Israeli troops”
                </Link>{" "}
                - BBC (Mar 2024){" "}
              </li>
              <li>
                {" "}
                <Link
                  to="http://chest...we/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ‘We cannot remain silent about what we saw…every one of us on
                  a daily basis treated pre-teen children who were shot in the
                  head and chest...We wish you could hear the cries and screams
                  our consciences will not let us forget.’: US doctors who
                  volunteered in Gaza demand ceasefire in letter to White House{" "}
                </Link>
                - CNN (Jul 2024)
              </li>
              <li>
                {" "}
                <Link
                  to="https://www.france24.com/en/tv-shows/t%C3%AAte-%C3%A0-t%C3%AAte/20240313-situation-in-gaza-completely-unimaginable-british-doctor-says-on-return"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Situation in Gaza 'completely unimaginable', British doctor
                  says after mission
                </Link>{" "}
                - France 24 (Mar 2024)
              </li>
              <li>
                {" "}
                <Link
                  to="https://www.doctorswithoutborders.org/latest/we-dread-nightfall-stories-gaza"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Testimonials from MSF staff in Gaza
                </Link>
                : ‘There is no safe place’... ‘I have been displaced 10
                times’... ‘Staff are literally kneeling on the floor in
                blood’...‘We don’t know if we will survive the next hour’...
                ‘There is nothing, nothing at all that justifies what I have
                seen today. Nothing’ ... ‘We reached the point we searched for
                animal food’... ‘Words can’t describe it’... ‘There isn’t even a
                safe place in people’s minds.’ - Doctors Without Borders / MSF
                (Oct 2024)
              </li>
            </ul>
          </p>

          <p class="detailtext">
            <span>Treaties </span>

            <ul class="detailedlists">
              <li>
                <Link
                  to="https://www.icrc.org/en/law-and-policy/geneva-conventions-and-their-commentaries"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Geneva Conventions + Additional Protocols
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.un.org/en/about-us/un-charter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Charter of the United Nations
                </Link>
              </li>
              <li>
                <Link
                  to="https://ihl-databases.icrc.org/en/ihl-treaties/genocide-conv-1948"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Convention on the Prevention and Punishment of the Crime of
                  Genocide{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="https://legal.un.org/avl/ha/catcidtp/catcidtp.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Convention against Torture and Other Cruel, Inhuman or
                  Degrading Treatment or Punishment{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="https://legal.un.org/avl/ha/cerd/cerd.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  International Convention on the Elimination of All Forms of
                  Racial Discrimination (ICERD)
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.icj-cij.org/other-texts/convention-on-the-privileges"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Convention on the Privileges and Immunities of the United
                  Nations
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.unicef.org/child-rights-convention"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Convention on the Rights of the Child
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.ohchr.org/en/instruments-mechanisms/instruments/international-covenant-civil-and-political-rights"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  International Covenant on Civil and Political Rights
                </Link>
              </li>
            </ul>
          </p>

          <p class="detailtext">
            <span>UN reports</span>
            <ul class="detailedlists">
              <li>
                UN General Assembly A/79/232 -{" "}
                <Link
                  to="https://documents.un.org/doc/undoc/gen/n24/262/79/pdf/n2426279.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report of the Independent International Commission of Inquiry
                  on the Occupied Palestinian Territory, including East
                  Jerusalem, and Israel{" "}
                </Link>
                (Sep 2024)
              </li>
              <li>
                UN General Assembly A/79/171 -{" "}
                <Link
                  to="https://documents.un.org/doc/undoc/gen/n24/212/30/pdf/n2421230.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report of the Special Rapporteur on the right to food -
                  Starvation and the right to food, with an emphasis on the
                  Palestinian people’s food sovereignty
                </Link>
              </li>
              <li>
                ESCWA and UNDP report -{" "}
                <Link
                  to="https://www.un.org/unispal/document/escwa-undp-oct-report-22oct24/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gaza War: Expected Socio-Economic Impacts on the State of
                  Palestine, Recovery scenarios
                </Link>
                (Oct 2024)
              </li>
              <li>
                UN General Assembly A/79/343 -{" "}
                <Link
                  to="https://www.un.org/unispal/document/unctad-report-10sep24/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  UNCTAD report: Economic impact of the Israeli military
                  operation in Gaza from October 2023 to May 2024{" "}
                </Link>
                (Sep 2024)
              </li>
            </ul>
          </p>

          <p class="detailtext">
            <span>International Court of Justice (ICJ)</span>
            <ul class="detailedlists">
              <li>
                <Link
                  to="https://www.icj-cij.org/court"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About the ICJ{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="https://en.wikipedia.org/wiki/South_Africa%27s_genocide_case_against_Israel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  South Africa v Israel - Wikipedia
                </Link>
              </li>
              <li>
                Full speech -{" "}
                <Link
                  to="https://www.news24.com/news24/southafrica/news/in-full-advocate-tembeka-ngcukaitobi-addresses-israels-genocidal-intent-at-the-icj-20240111"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  South Africa outlines case for genocidal intent
                </Link>
              </li>
              <li>
                ORDER of 26 January 2024 - Application of the Convention on the
                Prevention and Punishment of the Crime of Genocide in the Gaza
                Strip (South Africa v. Israel) -{" "}
                <Link
                  to="https://www.icj-cij.org/sites/default/files/case-related/192/192-20240126-pre-01-00-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press release
                </Link>
                /{" "}
                <Link
                  to="https://www.icj-cij.org/sites/default/files/case-related/192/192-20240126-sum-01-00-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Summary
                </Link>
                /
                <Link
                  to="https://global.upenn.edu/perryworldhouse/news/explaining-international-court-justices-ruling-israel-and-gaza"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  / Explainer
                </Link>
                (non-official)
              </li>
              <li>
                ORDER of 28 March 2024 - The Court indicates additional
                provisional measures -{" "}
                <Link
                  to="https://www.icj-cij.org/sites/default/files/case-related/192/192-20240328-pre-01-00-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press release
                </Link>
                /{" "}
                <Link
                  to="https://www.icj-cij.org/sites/default/files/case-related/192/192-20240328-ord-01-00-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Full text{" "}
                </Link>
                /{" "}
                <Link
                  to="https://www.jurist.org/features/2024/05/24/explainer-icj-orders-halt-to-israels-gaza-offensive-over-genocide-fears/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Explainer
                </Link>
                /
                <Link
                  to="https://www.justsecurity.org/94094/court-orders-unhindered-aid-gaza/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  c
                </Link>
                (non-official)
              </li>
              <li>
                ORDER of 24 May 2024 - The Court reaffirms its previous
                provisional measures and indicates new measures -{" "}
                <Link
                  to="https://www.icj-cij.org/sites/default/files/case-related/192/192-20240524-pre-01-00-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press release
                </Link>
                /{" "}
                <Link
                  to="https://www.icj-cij.org/sites/default/files/case-related/192/192-20240524-ord-01-00-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Full text{" "}
                </Link>
                /{" "}
                <Link
                  to="https://www.jurist.org/features/2024/05/24/explainer-icj-orders-halt-to-israels-gaza-offensive-over-genocide-fears/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Explainer
                </Link>
                (non-official)
              </li>
              <li>
                ADVISORY OPINION of 19 July 2024 - Legal Consequences arising
                from the Policies and Practices of Israel in the Occupied
                Palestinian Territory, including East Jerusalem (based on
                questions posed by the UN General Assembly) -{" "}
                <Link
                  to="https://www.icj-cij.org/sites/default/files/case-related/186/186-20240719-pre-01-00-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Press release{" "}
                </Link>
                /{" "}
                <Link
                  to="https://www.icj-cij.org/sites/default/files/case-related/186/186-20240719-sum-01-00-en.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Summary
                </Link>
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Readingmaterial;
