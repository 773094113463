import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ShareButtons = ({ url, text }) => {
  const encodedUrl = encodeURIComponent("http://enoughisenough.ch/");
  const encodedText = encodeURIComponent("Petition");

  return (
    <div class="sharesec ml-auto d-flex align-items-center">
      <span class="share-text">Share this petition</span>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="bi bi-facebook"></i>
      </a>
      <a
        href={`https://www.instagram.com/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="bi bi-instagram"></i>
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedText}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="bi bi-linkedin"></i>
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`}
        target="_blank"
        rel="noopener noreferrer"
        className="twitter-svg"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="15"
          fill="#fff"
          class="bi bi-twitter-x"
          viewBox="0 0 16 16"
        >
          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
        </svg>
      </a>
    </div>
  );
};

export default ShareButtons;
