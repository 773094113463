import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ShareButtons from "./ShareButtons";

function Footer() {
  return (
    <div>
      <footer>
        <nav class="navbar navbar-expand-lg header-navbar">
          <div class="navbar-brand" href="#">
            <img src="/assests/images/Logo.png" alt="Logo" />
          </div>
          <div class="banner-text">#ENOUGHISENOUGH</div>
          <ShareButtons></ShareButtons>
        </nav>
      </footer>
    </div>
  );
}

export default Footer;
