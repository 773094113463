import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import swal from "sweetalert";
import { db } from "../firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { countryList } from "../Services/countryList";

function Userdata() {
  const navigate = useNavigate();
  // State to hold the form data

  const [validated, setValidated] = useState(false);
  const [showNamePreference, setShowNamePreference] = useState("");
  const [showInformedPreference, setShowInformedPreference] = useState("");
  const [informProgressPreference, setInformProgressPreference] = useState("");
  const [error, setError] = useState({});
  const [success, setSuccess] = useState("");
  const [countryName, setCountryName] = useState([]);

  const [formData, setFormData] = useState({
    firstName: "",
    surname: "",
    email: "",
    country: "",
    gender: "",
    othergender: "",
    affiliated: "",
    workfor: "",
    showName: "",
    informed: "",
  });

  const checkUniqueEmail = async (email) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  };

  // Handle form field changes
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let timestamp = new Date();
    setFormData({
      ...formData,
      [name]: value, // Dynamically update the field
      timestamp: timestamp,
    });
  };

  const handleShowName = (val) => {
    setShowNamePreference(val);
    formData.showName = val;
  };

  const handleInform = (val) => {
    setShowInformedPreference(val);
    formData.informed = val;
  };

  // Handle form submit
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (
      form.checkValidity() === false ||
      !showNamePreference ||
      !showInformedPreference
    ) {
      event.stopPropagation();
      swal("", "Please fill all data", "warning");
      if (!showNamePreference || !showInformedPreference) {
        let errMsg = "Please select any one option";
        if (!showNamePreference) {
          setError({
            question: "name",
            msg: errMsg,
          });
        } else {
          setError({
            question: "informed",
            msg: errMsg,
          });
        }
      } else {
        setError("");
        // Handle the form submission logic (e.g., send data to a server)
      }
    } else {
      postData(formData);
      event.preventDefault();
    }

    setValidated(true);
    // Log the form data object
  };

  const postData = async (data) => {
    setError("");
    setSuccess("");

    // Validate email uniqueness
    const isUniqueEmail = await checkUniqueEmail(formData.email);
    if (!isUniqueEmail) {
      swal("", "Email is already in use.", "warning").then((val) => {
        return;
      });
      return;
    }

    // Add data to Firestore
    try {
      await addDoc(collection(db, "users"), data);
      swal("", "Thank you for signing the petition", "success").then((val) => {
        if (val) {
          navigate("/petitiondetail");
        }
      });

      // setFormData({
      //   firstName: "",
      //   surname: "",
      //   email: "",
      //   country: "",
      //   gender: "",
      //   affiliated: "",
      //   othergender: "",
      // });
      //   setFormData({ email: "", address: "", age: "" }); // Reset form
    } catch (err) {
      swal("Failed to submit form. Please try again.");
      console.error(err);
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="First name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Surname</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Surname"
            name="surname"
            value={formData.surname}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      {/* Email field with validation */}
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustomEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Enter email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        {/* Dropdown with validation */}
        <Form.Group as={Col} md="6" controlId="validationCustomSelect">
          <Form.Label>Country</Form.Label>
          <Form.Control
            as="select"
            required
            name="country"
            value={formData.country}
            onChange={handleChange}
          >
            <option value="">Select Country</option>
            {countryList &&
              countryList.map((item) => {
                return <option value={item}>{item}</option>;
              })}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select a country.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="validationCustomSelectGender">
          <Form.Label>Gender</Form.Label>
          <Form.Control
            as="select"
            required
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select a gender.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      {formData.gender == "Other" && (
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="6"
            controlId="validationCustom02"
          ></Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Please specify gender</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Other Gender"
              name="othergender"
              value={formData.othergender}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
      )}

      <Row className="mb-3 affilatedDiv">
        {/* Dropdown with validation */}
        <Form.Group as={Col} md="6" controlId="validationCustomSelect">
          <Form.Label>I work for or am affiliated with the:</Form.Label>
          <Form.Control
            as="select"
            required
            name="affiliated"
            value={formData.affiliated}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="UN organisation">UN organisation</option>
            <option value="International Non-Governmental Organisations (INGOs)">
              International Non-Governmental Organisations (INGOs)
            </option>
            <option value="Non-Governmental Organisations (NGOs)">
              {" "}
              Non-Governmental Organisations (NGOs)
            </option>
            <option value="Civil Society/Community Based Organisations">
              Civil Society/Community Based Organisations
            </option>
            <option value="Medical University or Research Institution - (1) Faculty Members (2) Students">
              {" "}
              Medical University or Research Institution - (1) Faculty Members
              (2) Students
            </option>
            <option value="Health and Medical Sector (1) Public or (2) Private">
              Health and Medical Sector (1) Public or (2) Private
            </option>
            <option value="The Media">The Media</option>
            <option value="Other">Other</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select any one option.
          </Form.Control.Feedback>
        </Form.Group>

        {formData.affiliated == "Other" && (
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Please specify</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder=""
              name="workfor"
              value={formData.workfor}
              onChange={handleChange}
            />
          </Form.Group>
        )}
      </Row>

      <Row>
        <div className="radiorow">
          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="showNamePreference"
                id="flexRadioDefault1"
                value="Yes. I am willing to have my name shown on this petition"
                checked={
                  showNamePreference ===
                  "Yes. I am willing to have my name shown on this petition"
                }
                onChange={(e) => handleShowName(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Yes. I am willing to have my name shown on this petition
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="showNamePreference"
                id="flexRadioDefault2"
                value="No. I prefer not to have my name shown on this petition"
                checked={
                  showNamePreference ===
                  "No. I prefer not to have my name shown on this petition"
                }
                onChange={(e) => handleShowName(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                No. I prefer not to have my name shown on this petition
              </label>
            </div>
            {Object.keys(error).length > 0 && error.question == "name" ? (
              <div
                type="invalid"
                style={{ color: "#b02a37", marginTop: "10px" }}
                className="erroMsg"
              >
                <p>{error.msg}</p>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="showInformedPreference"
                id="flexRadioDefault3"
                value="Yes, I would like to be informed of the progress of the petition"
                checked={
                  showInformedPreference ===
                  "Yes, I would like to be informed of the progress of the petition"
                }
                onChange={(e) => handleInform(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault3">
                Yes, I would like to be informed of the progress of the petition
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="showInformedPreference"
                id="flexRadioDefault4"
                value="No. I don’t want to hear about this petition’s progress"
                checked={
                  showInformedPreference ===
                  "No. I don’t want to hear about this petition’s progress"
                }
                onChange={(e) => handleInform(e.target.value)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault4">
                No. I don’t want to hear about this petition’s progress
              </label>
            </div>

            {Object.keys(error).length > 0 && error.question == "informed" ? (
              <div
                type="invalid"
                style={{ color: "#b02a37", marginTop: "10px" }}
                className="erroMsg"
              >
                <p>{error.msg}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Row>

      <Button className="btn btn-primary mt-3 signbtn" type="submit">
        Click here to sign this petition
      </Button>
    </Form>
  );
}

export default Userdata;
