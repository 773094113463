import React from "react";
import Formdata from "./Formdata";
import Userdata from "./Userdata";

function Signpetition() {
  return (
    <div class="container-fluid petitiontext">
      <div class="row petitionrow furthersec">
        <div class="col-lg-12  col-sm-12 progressfieldsec">
          {/* <!-- Progress Section --> */}
          {/* <Formdata /> */}
          <Userdata />
        </div>
      </div>
    </div>
  );
}

export default Signpetition;
