// src/App.js
import React from "react";
import SubmissionForm from "./components/SubmissionForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Petitiondetail from "./components/Petitiondetail";
import Readingmaterial from "./components/Readingmaterial";
import Signpetition from "./components/Signpetition";
import Footer from "./components/Footer";
import Banner from "./components/Banner";

function App() {
  return (
    <div className="App">
      {/* <SubmissionForm /> */}
      <Header />
      <Banner />
      <Routes>
        <Route index element={<Petitiondetail />} />
        <Route index path="/petitiondetail" element={<Petitiondetail />} />
        <Route path="/readingmaterial" element={<Readingmaterial />} />
        <Route path="/signpetition" element={<Signpetition />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
